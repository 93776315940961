<template>
	<div>
		<div 
			v-if="ongoingCampaigns && ongoingCampaigns.length"
			class="mb-8">
			
			<div class="text-black pb-4">
				Kampanjer
			</div>
			
			<div class="grid gap-4">
				<div 
					v-for="campaign in ongoingCampaigns"
					:key="'campaign_'+campaign.campaignId"
					@click="onCampaignClick( campaign )"
					class="flex">
					
					<CampaignCard 
						:campaign="campaign"
						:small="true"
					/>
				</div>
			</div>
		</div>
		
		<div v-if="department">
			<form 
				v-if="entity == 'products'"
				@submit.prevent="onCreateTransaction()">
				
				<div class="flex mb-4 text-black border-b border-beige pb-4">
					<div class="flex-grow">
						Produkt
					</div>
					<div class="flex-none w-20 text-left">
						Antall
					</div>
					<div class="flex-none w-24 text-center">
						Á kr
					</div>
				</div>
				
				<div v-if="department.products">
					<div 
						v-for="product in departmentProductsSorted"
						:key="'product_'+product.productId"
						class="flex border-b border-beige items-start mb-4">
						
						<div 
							@click="onToggleProduct( product )"
							class="flex-none cursor-default mt-3">
							
							<input 
								type="checkbox" 
								:checked="transactionProducts.find( t => t.productId == product.productId)"
								disabled
								class="pointer-events-none"
								name="checkbox"
							/>
						</div>
						
						<div 
							@click="transactionProducts.find( t => t.productId == product.productId) ? null : onToggleProduct( product )"
							class="flex-grow">
							
							<div 
								@click="transactionProducts.find( t => t.productId == product.productId) ? null : onToggleProduct( product )"
								class="flex-grow flex items-center gap-4">
								
								<div 
									class="flex-grow mb-3"
									:class="transactionProducts.find( t => t.productId == product.productId) ? 'opacity-100' : 'opacity-75 cursor-pointer'">
									
									{{product.name}}
								</div>
								
								<div 
									class="flex-none w-6 h-6 mb-4 flex items-center justify-center"
									v-if="
										ongoingCampaigns && ongoingCampaigns.some( c => {
											if (c.campaignTargets[ entity ].mode == 'all'){
												return true
											}
											else {
												return c.products.find(p => p.productId == product.productId)
											}
										})
									">
									
									<SvgIcon 
										icon="campaigns" 
										class="w-6 h-6 text-green"
									/>
								</div>
								
								<div class="flex-none w-24">
									<div 
										v-if="transactionProducts.find( t => t.productId == product.productId)"
										class="flex items-start justify-center mx-2 space-x-1">
										
										<i 
											@click="transactionProducts.find( t => t.productId == product.productId).qty--"
											class="flex-none fas fa-minus text-gray-dark w-6 h-12 flex items-center justify-center mb-3"
											:class="transactionProducts.find( t => t.productId == product.productId).qty > 1 ? '' : 'opacity-25 pointer-events-none'"
										/>
										
										<input 
											type="number" 
											v-model="transactionProducts.find( t => t.productId == product.productId).qty"
											class="text-center flex-grow"
											style="padding-left: 0; padding-right: 0; min-width: 3rem;"
											name="qty"
										/>
										
										<i 
											@click="transactionProducts.find( t => t.productId == product.productId).qty++"
											class="flex-none fas fa-plus text-gray-dark w-6 h-12 flex items-center justify-center mb-3"
										/>
									</div>
									<input 
										v-else
										type="number" 
										value="1"
										class="text-center pointer-events-none opacity-50"
									/>
								</div>
								
								<div class="flex-none w-20">
									<input 
										v-if="transactionProducts.find( t => t.productId == product.productId)"
										type="number" 
										v-model="transactionProducts.find( t => t.productId == product.productId).value"
										class="text-right"
										name="value"
									/>
									<input 
										v-else
										type="number" 
										v-model="product.value"
										class="text-right pointer-events-none opacity-50"
									/>
								</div>
							</div>
							<div v-if="transactionProducts.find( t => t.productId == product.productId)">
								<input 
									type="text" 
									v-model="transactionProducts.find( t => t.productId == product.productId).comment"
									class="flex-grow"
									name="comment"
									placeholder="Kommentar"
								/>
							</div>
						</div>
					</div>
					
				</div>
				<div v-else>
					Fant ingen produkter
				</div>
			</form>
			<form 
				v-else-if="entity == 'tasks'"
				@submit.prevent="onCreateTransaction()">
				
				<div class="flex mb-4 text-black border-b border-beige pb-4">
					<div class="flex-grow">
						Oppgave
					</div>
					<div class="flex-none w-20 text-left">
						Antall
					</div>
				</div>
				
				<div v-if="department.tasks">
					<div 
						v-for="task in department.tasks"
						:key="'task_'+task.taskId"
						class="flex border-b border-beige items-start mb-4">
						
						<div 
							@click="onToggleTask( task )"
							class="flex-none cursor-default mt-3">
							
							<input 
								type="checkbox" 
								:checked="transactionTasks.find( t => t.taskId == task.taskId)"
								disabled
								class="pointer-events-none"
								name="checkbox"
							/>
						</div>
						
						
						
						
						<div 
							@click="transactionTasks.find( t => t.taskId == task.taskId) ? null : onToggleTask( task )"
							class="flex-grow">
						
							<div 
								@click="transactionTasks.find( t => t.taskId == task.taskId) ? null : onToggleTask( task )"
								class="flex-grow flex items-center gap-4 ">
								
								<div 
									class="flex-grow mb-3"
									:class="transactionTasks.find( t => t.taskId == task.taskId) ? 'opacity-100' : 'opacity-75'">
									
									{{task.name}}
								</div>
								
								<div 
									class="flex-none w-6 h-6 mb-4 flex items-center justify-center"
									v-if="
										ongoingCampaigns && ongoingCampaigns.some( c => {
											if (c.campaignTargets[ entity ].mode == 'all'){
												return true
											}
											else {
												return c.tasks.find(t => t.taskId == task.taskId)
											}
										})
									">
									
									<SvgIcon 
										icon="campaigns" 
										class="w-6 h-6 text-green"
									/>
								</div>
								
								
								
								<div class="flex-none w-24 ">
									<div 
										v-if="transactionTasks.find( t => t.taskId == task.taskId)"
										class="flex items-start justify-center mx-2 space-x-1">
										
										<i 
											@click="transactionTasks.find( t => t.taskId == task.taskId).qty--"
											class="flex-none fas fa-minus text-gray-dark w-6 h-12 flex items-center justify-center mb-3"
											:class="transactionTasks.find( t => t.taskId == task.taskId).qty > 1 ? '' : 'opacity-25 pointer-events-none'"
										/>
										
										<input 
											type="number" 
											v-model="transactionTasks.find( t => t.taskId == task.taskId).qty"
											class="text-center flex-grow"
											style="padding-left: 0; padding-right: 0; min-width: 3rem;"
											name="qty"
										/>
										
										<i 
											@click="transactionTasks.find( t => t.taskId == task.taskId).qty++"
											class="flex-none fas fa-plus text-gray-dark w-6 h-12 flex items-center justify-center mb-3"
										/>
									</div>
									<input 
										v-else
										type="number" 
										value="1"
										class="text-center pointer-events-none opacity-50"
									/>
								</div>
							</div>
							<div v-if="transactionTasks.find( t => t.taskId == task.taskId)">
								<input 
									type="text" 
									v-model="transactionTasks.find( t => t.taskId == task.taskId).comment"
									class="flex-grow"
									name="comment"
									placeholder="Kommentar"
								/>
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					Fant ingen oppgaver
				</div>
			</form>
		</div>
			
			
		<Modal 
			v-if="showCampaignInformation"
			@close="showCampaignInformation = null"
			insideClasses="bg-white"
			width="24rem">
			<!-- width="auto"> -->
			
			<header slot="header">
				{{showCampaignInformation.name}}
			</header>
			
			<CampaignInformation :campaign="showCampaignInformation" />
			
			<!-- <footer slot="footer">
				<div class="space-x-4 text-right">
					<a 
						class="button" 
						@click="showCampaignInformation = null">
						
						Lukk
					</a>
				</div>
			</footer> -->
		</Modal>
		
	</div>
</template>

<script>
export default {
	components: {
		'CampaignCard': () => import('@/components/campaigns/CampaignCard.vue'),
		'CampaignInformation': () => import('@/components/campaigns/CampaignInformation.vue'),
	},
	
	props: { 
		departmentId: {
			type: String,
			required: true,
		},
		entity: {
			type: String,
			required: false,
			default: 'products'
		},
	},
	
	computed:{
		departments(){
			return this.$store.getters.getDepartments;
		},
		
		department(){
			return this.departments.find( d => d.departmentId == this.departmentId);
		},
		
		departmentProductsSorted(){
			if (!this.department.products) {
				return null;
			}
			
			let products = [];
			let productsInCampaign = [];
			
			// first, find all campaigns and included products
			if (this.ongoingCampaigns) {
				this.department.products.forEach( product => {
					
					const isIncluded = this.ongoingCampaigns.some( c => {
						if (c.productsMode == 'all'){
							return true;
						}
						else {
							return c.products && c.products.find(p => p.productId == product.productId)
						}
					});
					
					if (isIncluded) {
						productsInCampaign.push( product );
					}
				});
			}
			
			products = [
				...productsInCampaign,
				...this.department.products.filter( p => !productsInCampaign.includes( p ) ),
			];
			
			return products;
		},
		
		campaigns(){
			return this.$store.getters.getCampaigns;
		},
		
		ongoingCampaigns(){
			if (!this.campaigns) {
				return
			}
			return this.campaigns
				.filter( c => c.published )
				.filter( c => c.status == 'ongoing')
				.filter( c => c.departmentId == this.departmentId)
				.filter( c => c.type == this.entity );
		},
	},
	
	watch: {
		transactionProducts(newVal, oldVal){
			const transaction = {
				products: newVal,
				departmentId: this.departmentId,
			};
			
			this.$emit('onTransactionChanged', transaction);
		},
		
		transactionTasks(newVal, oldVal){
			const transaction = {
				tasks: newVal,
				departmentId: this.departmentId,
			};
			
			this.$emit('onTransactionChanged', transaction);
		}
	},
	
	data(){
		return {
			loading: null,
			transactionProducts: [],
			transactionTasks: [],
			// expanded: [],
			showCampaignInformation: null,
		}
	},
	
	methods: {
		onReset(){
			this.transactionProducts = [];
			this.transactionTasks = [];
		},
		
		onToggleProduct( product ){
			const transaction = this.transactionProducts.find( t => t.productId == product.productId);
			
			if ( !transaction ) {
				this.transactionProducts.push( {
					...product,
					qty: 1,
					comment: null,
				});
			}
			else {
				this.transactionProducts = this.transactionProducts.filter( t => t.productId != product.productId);
			}
		},
		
		onToggleTask( task ){
			const transaction = this.transactionTasks.find( t => t.taskId == task.taskId);
			
			if ( !transaction ) {
				this.transactionTasks.push( {
					...task,
					qty: 1,
					comment: null,
				});
			}
			else {
				this.transactionTasks = this.transactionTasks.filter( t => t.taskId != task.taskId);
			}
		},
		
		onCampaignClick( campaign ){
			console.log('campaign', campaign);
			this.showCampaignInformation = campaign;
			// if ( this.expanded.includes( campaign.campaignId ) ) {
			//     this.expanded = this.expanded.filter( campaignId => campaignId != campaign.campaignId);
			// }
			// else {
			//     this.expanded.push( campaign.campaignId );
			// }
		},
	},
	
	mounted(){
		// console.log('mounted');
		this.$emit('onTransactionChanged', null);
		
	}
}
</script>

<style lang="scss" scoped>
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
</style>
